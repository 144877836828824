<template>
  <div>
    <div
      id="toolbar"
      :class="{
        'rounded-top': rounded === 'top' || rounded === 'all',
      }"
    >
      <span class="ql-formats">
        <select class="ql-font" v-if="enabledButtons.includes('font')">
          <!-- Note a missing, thus falsy value, is used to reset to default -->
          <option selected></option>
          <option value="serif"></option>
          <option value="monospace"></option>
        </select>
        <select class="ql-size" v-if="enabledButtons.includes('size')">
          <option value="small"></option>
          <!-- Note a missing, thus falsy value, is used to reset to default -->
          <option selected></option>
          <option value="large"></option>
          <option value="huge"></option>
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-bold" v-if="enabledButtons.includes('bold')"></button>
        <button
          class="ql-italic"
          v-if="enabledButtons.includes('italic')"
        ></button>
        <button
          class="ql-underline"
          v-if="enabledButtons.includes('underline')"
        ></button>
        <button
          class="ql-strike"
          v-if="enabledButtons.includes('strike')"
        ></button>
        <button
          class="ql-script"
          value="sub"
          v-if="enabledButtons.includes('subscript')"
        ></button>
        <button
          class="ql-script"
          value="super"
          v-if="enabledButtons.includes('superscript')"
        ></button>
        <button
          class="ql-clean"
          v-if="enabledButtons.includes('clean')"
        ></button>
      </span>
      <span class="ql-formats">
        <button
          class="ql-list"
          value="ordered"
          v-if="enabledButtons.includes('list-ordered')"
        ></button>
        <button
          class="ql-list"
          value="bullet"
          v-if="enabledButtons.includes('list-bullet')"
        ></button>
        <button
          class="ql-indent"
          value="+1"
          v-if="enabledButtons.includes('indent')"
        ></button>
        <button
          class="ql-indent"
          value="-1"
          v-if="enabledButtons.includes('outdent')"
        ></button>
        <select class="ql-align" v-if="enabledButtons.includes('align')">
          <option value=""></option>
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>
      </span>
      <span class="ql-formats">
        <button
          class="ql-blockquote"
          v-if="enabledButtons.includes('blockquote')"
        ></button>
        <button
          class="ql-code-block"
          v-if="enabledButtons.includes('code-block')"
        ></button>
      </span>
      <span class="ql-formats">
        <button class="ql-link" v-if="enabledButtons.includes('link')"></button>
        <button
          class="ql-image"
          v-if="enabledButtons.includes('image')"
        ></button>
      </span>
      <span class="ql-formats">
        <slot name="custom-buttons" v-bind="this.editor"></slot>
      </span>
    </div>
    <div
      id="editor"
      :class="{
        'rounded-bottom': rounded === 'bottom' || rounded === 'all',
      }"
    ></div>
  </div>
</template>

<script>
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import BlotFormatter from "quill-blot-formatter";
import { storage } from "../firebase";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "TextEditor",
  props: {
    placeholder: {
      type: String,
      required: false,
      default: "Text goes here...",
    },
    rounded: {
      type: String,
      required: false,
      default: "none",
    },
    theme: {
      type: String,
      required: false,
      default: "snow",
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
    },
    buttons: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    preset: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      containers: {
        editor: "#editor",
        toolbar: "#toolbar",
      },
      editor: null,
      presets: {
        basic: [
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "subscript",
          "superscript",
          "clean",
          "list-ordered",
          "list-bullet",
          "indent",
          "outdent",
        ],
        full: [
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "subscript",
          "superscript",
          "clean",
          "list-ordered",
          "list-bullet",
          "indent",
          "outdent",
          "align",
          "blockquote",
          "code-block",
          "link",
          "image",
        ],
      },
    };
  },
  computed: {
    enabledButtons() {
      const presets = Object.keys(this.presets);
      if (presets.includes(this.preset)) {
        return this.presets[this.preset];
      } else if (this.buttons.length > 0) {
        return this.buttons;
      } else {
        return this.presets.full;
      }
    },
  },
  watch: {
    value: {
      immediate: false,
      handler(val) {
        if (val != this.editor.root.innerHTML && !this.editor.hasFocus()) {
          this.editor.root.innerHTML = val;
        }
      },
    },
    disabled: {
      immediate: false,
      handler(status) {
        this.editor.enable(!status);
      },
    },
  },
  methods: {
    initializeEditor() {
      this.registerModules();
      this.editor = new Quill(this.containers.editor, {
        theme: this.theme,
        placeholder: this.placeholder,
        modules: {
          toolbar: {
            container: this.containers.toolbar,
          },
          blotFormatter: {},
        },
        readOnly: this.disabled ? this.disabled : false,
      });
      this.handleInitialContent();
      this.registerEditorEventListeners();
      this.registerCustomHandlers();
      this.$emit("ready", this.editor);
    },
    registerModules() {
      Quill.register("modules/blotFormatter", BlotFormatter);
    },
    registerEditorEventListeners() {
      this.editor.on("text-change", this.handleTextChange);
      this.editor.on("selection-change", this.handleSelectionChange);
      this.listenForEditorEvent("text-change");
      this.listenForEditorEvent("selection-change");
      this.listenForEditorEvent("editor-change");
    },
    registerPrototypes() {
      Quill.prototype.getHTML = function () {
        return this.container.querySelector(".ql-editor").innerHTML;
      };
      Quill.prototype.getWordCount = function () {
        return this.container.querySelector(".ql-editor").innerText.length;
      };
    },
    listenForEditorEvent(type) {
      this.editor.on(type, (...args) => {
        this.$emit(type, ...args);
      });
    },
    handleInitialContent() {
      if (this.value) this.editor.root.innerHTML = this.value;
    },
    handleSelectionChange(range, oldRange) {
      if (!range && oldRange) this.$emit("blur", this.editor);
      else if (range && !oldRange) this.$emit("focus", this.editor);
    },
    handleTextChange(delta, oldContents) {
      let editorContent =
        this.editor.getHTML() === "<p><br></p>" ? "" : this.editor.getHTML();
      this.$emit("input", editorContent);
      if (this.useCustomImageHandler)
        this.handleImageRemoved(delta, oldContents);
    },
    registerCustomHandlers() {
      const toolbar = this.editor.getModule("toolbar");
      // Add custom image handler
      toolbar.addHandler("image", () => {
        this.uploadImage();
      });
    },
    uploadImage() {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      // Listen for file and upload to Firebase
      input.onchange = () => {
        const file = input.files[0];
        const extension = file.name.split(".").pop();
        const filename = uuidv4() + "." + extension;
        const ref = storage.ref().child("articles/" + filename);
        ref.put(file).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            // Insert into editor
            const range = this.editor.getSelection();
            this.editor.insertEmbed(range.index, "image", url);
          });
        });
      };
    },
  },
  mounted() {
    // Initialize Quill editor
    this.registerPrototypes();
    this.initializeEditor();
  },
  beforeDestroy() {
    this.editor = null;
    delete this.editor;
  },
};
</script>

<style lang="scss">
body[data-theme="dark"] {
  .ql-toolbar.ql-snow {
    border-color: rgba(255, 255, 255, 0.125);
  }
  .ql-container.ql-snow {
    border-color: rgba(255, 255, 255, 0.125);
  }
  .ql-picker-label {
    color: #a9acb3;
  }
  .ql-fill {
    fill: #a9acb3;
  }
  .ql-stroke {
    stroke: #a9acb3;
  }
  .ql-editor.ql-blank::before {
    color: #a9acb3;
  }
}

.ql-snow .ql-color-picker .ql-picker-label,
.ql-snow .ql-icon-picker .ql-picker-label {
  padding: 0px 4px;
}

.rounded-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-bottom {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
</style>
