<template>
  <div class="row help-content-row">
    <b-container fluid class="px-0">
      <b-row>
        <b-col>
          <b-alert show variant="info" v-if="topLevel">
            <div class="alert-message d-flex justify-content-between">
              <h6 class="alert-heading float-left mt-2 mr-2">
                <i class="fas fa-question-circle mr-2"></i> Need help?
                <b-link :to="{ name: 'submit-ticket' }">Submit a ticket</b-link>
              </h6>
              <b-button variant="secondary" @click="$emit('show-tickets')"
                >View Tickets</b-button
              >
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-container fluid class="category-container px-0">
            <b-row v-if="topLevel">
              <b-col
                sm="12"
                md="6"
                v-for="(category, index) in categoryList"
                :key="index"
                class="d-flex justify-content-center category-col"
              >
                <category-card
                  :category="category"
                  @click="exploreCategory(category)"
                >
                </category-card>
              </b-col>
            </b-row>
            <b-row v-if="!topLevel">
              <b-col sm="12">
                <b-alert show variant="secondary" v-if="!topLevel">
                  <div class="alert-message d-flex justify-content-between">
                    <h6 class="alert-heading float-left mt-2 mr-2">
                      <b-link class="text-dark" @click="goHome"
                        ><i class="fas fa-home"></i
                      ></b-link>
                      <span v-for="(breadcrumb, index) in path" :key="index">
                        <i class="fas fa-angle-right mx-2"></i>
                        <b-link
                          class="text-dark"
                          @click="goToBreadcrumb(breadcrumb, index)"
                          >{{ breadcrumb.name }}</b-link
                        >
                      </span>
                    </h6>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
            <b-row
              v-if="!topLevel"
              class="d-flex justify-content-center w-100 px-5"
            >
              <b-col
                sm="12"
                v-for="(category, index) in categoryList"
                :key="index"
                class="d-flex justify-content-center w-100"
              >
                <b-card
                  class="mb-3 w-100 cursor-pointer"
                  @click="exploreCategory(category)"
                >
                  <h4 class="card-title">
                    <i class="far fa-folder mr-2 text-muted"></i
                    >{{ category.name }}
                  </h4>
                  <b-card-text>
                    {{ category.description }}
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
            <b-row
              v-if="!topLevel"
              class="d-flex justify-content-center w-100 px-5"
            >
              <b-col
                sm="12"
                v-for="(article, index) in articleList"
                :key="index"
                class="d-flex justify-content-center w-100"
              >
                <b-card
                  :title="article.name"
                  :sub-title="article.description"
                  class="mb-3 w-100 cursor-pointer"
                  @click="readArticle(article['.key'])"
                >
                  <b-card-text>
                    {{ previewArticleText(article.text) }}
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
            <b-row
              v-if="!topLevel"
              class="d-flex justify-content-center w-100 px-5"
            >
              <b-col sm="12" class="d-flex justify-content-left">
                <b-link @click="goBack()">
                  <i class="fas fa-arrow-circle-left"></i> Back
                </b-link>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import CategoryCard from "@/components/CategoryCard.vue";

export default {
  name: "KnowledgeBase",
  mixins: [FirebaseMixin, UtilsMixin],
  components: { "category-card": CategoryCard },
  data() {
    return {
      ref: "articles",
      bind: [],
      images: [],
      articles: [],
      root: ["articles"],
      path: [],
    };
  },
  watch: {
    root: {
      immediate: true,
      handler(root) {
        this.bindObject(root.join("/"), null, "articles");
      },
    },
    path: {
      immediate: false,
      handler() {
        history.replaceState({}, null, "/help/kb/" + this.pathString);
      },
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    categoryList() {
      return this.articles.filter((article) => {
        return article.type === "category";
      });
    },
    articleList() {
      return this.articles.filter((article) => {
        return article.type === "article" && article.published === true;
      });
    },
    topLevel() {
      return this.root.length === 1;
    },
    pathString() {
      return this.path
        .map((breadcrumb) => {
          return breadcrumb.slug;
        })
        .join("/");
    },
  },
  methods: {
    goHome() {
      this.root = ["articles"];
      this.path = [];
    },
    goBack() {
      this.root.splice(-2, 2);
      this.path.pop();
    },
    exploreCategory(category) {
      console.log(category);
      this.root.push(category[".key"], "articles");
      this.path.push({
        name: category.name,
        slug: category.slug,
        root: [...this.root],
      });
    },
    readArticle(id) {
      this.$router.push("/help/articles/" + this.pathString + "/" + id);
    },
    goToBreadcrumb(breadcrumb, index) {
      console.log(breadcrumb);
      this.root = breadcrumb.root;
      this.path.splice(index, this.path.length - index);
    },
    async setRootFromPath(routePath) {
      console.log("Route Path: " + routePath);
      const segments = routePath.split("/");
      let root = ["articles"];
      let path = [];
      for (const segment of segments) {
        const snapshot = await this.getObjectSnapshot(
          root.join("/"),
          null,
          true
        );
        snapshot.forEach(function (childSnapshot) {
          const key = childSnapshot.key;
          const category = childSnapshot.val();
          if (
            category.type === "category" &&
            category.slug === segment.toLowerCase()
          ) {
            root.push(key, "articles");
            path.push({
              name: category.name,
              slug: category.slug,
              root: [...root],
            });
          }
        });
      }
      this.root = [...root];
      this.path = [...path];
    },
    previewArticleText(text) {
      return (
        text
          .replace(/(<([^>]+)>)/gi, "")
          .replace(/&nbsp;/g, " ")
          .substring(0, 100) + "..."
      );
    },
  },
  created() {
    if (this.$route.params.path !== undefined) {
      this.setRootFromPath(this.$route.params.path);
    }
  },
};
</script>

<style scoped>
.category-container {
  max-width: 1068px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
