<template>
  <div class="row ticket-wrapper">
    <div class="col-xs-12 col-md-3">
      <b-card no-body class="shadow-sm">
        <b-list-group flush>
          <b-list-group-item class="d-flex justify-content-between"
            ><span class="emphasis">#</span> {{ ticket.id }}</b-list-group-item
          >
          <b-list-group-item class="d-flex justify-content-between"
            ><span class="emphasis">Status</span>
            {{ ticket.status }}</b-list-group-item
          >
          <b-list-group-item class="d-flex justify-content-between"
            ><span class="emphasis">Assigned</span>
            {{ ticket.assigned.name }}</b-list-group-item
          >
          <b-list-group-item class="d-flex justify-content-between"
            ><span class="emphasis">Created</span>
            {{ formatDate(ticket.created) }}</b-list-group-item
          >
          <b-list-group-item class="d-flex justify-content-between"
            ><span class="emphasis">Last Updated</span>
            {{ formatDate(lastUpdate.date) }}</b-list-group-item
          >
        </b-list-group>
      </b-card>

      <div class="card mt-3 shadow-sm">
        <b-form inline>
          <b-button
            variant="secondary"
            class="mr-1 ticket-button"
            :to="{ name: 'help', params: { page: 'tickets' } }"
            ><i class="far fa-arrow-alt-circle-left"></i> Back
          </b-button>
          <b-button
            variant="danger"
            class="ticket-button"
            @click="showCloseTicket()"
            ><i class="far fa-window-close"></i> Close Ticket
          </b-button>
        </b-form>
      </div>
    </div>

    <div class="col-xs-12 col-md-9">
      <div id="ticket-new-update" class="card comment-box shadow-sm">
        <div class="card-body">
          <text-editor
            placeholder="Add an update..."
            rounded="top"
            preset="basic"
            v-model="update.message"
          ></text-editor>
        </div>
        <div class="card-footer clearfix">
          <ul class="nav nav-pills float-left">
            <!--<li role="presentation"><a href="#"><i class="fa fa-camera"></i></a></li>
                    <li role="presentation"><a href="#"><i class="fa fa-music"></i></a></li>
                    <li role="presentation"><a href="#"><i class="fa fa-map-marker"></i></a></li>-->
          </ul>
          <b-button variant="primary" class="float-right" @click="submit">
            <b-spinner small v-if="processing"></b-spinner>
            <span v-if="!processing">Post</span>
          </b-button>
        </div>
      </div>

      <b-card no-body class="shadow-sm mt-3">
        <b-list-group flush>
          <b-list-group-item
            class="flex-column align-items-start"
            v-for="(update, index) in ticket.updates"
            :key="index"
          >
            <div class="d-flex w-100 justify-content-between">
              <span>
                <h5 class="mb-1 text-primary">{{ deriveHeader(update) }}</h5>
              </span>
              <small>{{ formatDate(update.date) }}</small>
            </div>

            <p class="my-4" v-html="update.message"></p>

            <small
              ><b-avatar class="mr-1"></b-avatar>
              {{
                update.type === "new-message" ? userAuthorName : update.author
              }}</small
            >
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </div>

    <b-modal
      id="modal_close"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Close Ticket"
      ok-title="Close"
      @ok="closeTicket"
    >
      <p class="my-2">Please explain why you are closing the ticket:</p>
      <b-form-textarea
        v-model="close.message"
        placeholder="Explanation..."
        rows="6"
        max-rows="6"
        no-resize
      ></b-form-textarea>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Ticket from "@/firebase/tickets";
import TextEditor from "@/components/TextEditor";
import moment from "moment";

export default {
  name: "ReadTicket",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: { TextEditor },
  data() {
    return {
      ref: "tickets",
      bind: [],
      refkey: null,
      ticket: {
        id: "",
        status: "",
        assigned: {
          name: "Unassigned",
        },
        updates: [{}],
      },
      update: {
        message: "",
      },
      close: {
        message: "",
      },
      processing: false,
    };
  },
  watch: {
    userUid: {
      immediate: false,
      handler(uid) {
        if (uid) {
          this.bindObject(
            this.ref + "/" + uid + "/" + this.refkey,
            null,
            "ticket"
          );
        }
      },
    },
    ticket: {
      immediate: true,
      handler(ticket) {
        if (ticket !== null && !("assigned" in ticket)) {
          this.ticket.assigned = { name: "Unassigned" };
        }
      },
    },
    refkey: {
      immediate: true,
      handler(key) {
        if (this.userUid) {
          this.bindObject(
            this.ref + "/" + this.userUid + "/" + key,
            null,
            "ticket"
          );
        }
      },
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["userAuthorName"]),
    userUid() {
      return this.userProfile.uid;
    },
    updates() {
      return Object.values(this.ticket.updates);
    },
    lastUpdate() {
      return this.updates[this.updates.length - 1];
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).fromNow();
    },
    submit() {
      this.processing = true;
      const t = new Ticket();
      t.update(this.refkey, this.update)
        .then(() => {
          this.notyf.success("Ticket updated successfully.");
          this.processing = false;
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while updating the ticket. Please try again later."
          );
          this.processing = false;
        });
    },
    deriveHeader(update) {
      switch (update.type) {
        case "update":
          return "New reply from " + update.author;
        case "close-message":
          return "Ticket closed by " + this.userAuthorName;
        default:
          return "New message from " + this.userAuthorName;
      }
    },
    showCloseTicket() {
      this.$bvModal.show("modal_close");
    },
    closeTicket() {
      const t = new Ticket();
      t.close(this.refkey, this.close.message).catch((error) => {
        console.log(error);
        this.notyf.error(
          "An error occurred while closing the ticket. Please try again later."
        );
      });
      this.close.message = "";
    },
  },
  created() {
    this.refkey = this.$route.params.id;
  },
};
</script>

<style scoped>
.ticket-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

/* .contentarea {
  background-color: transparent;
}

.card {
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
} */

.trumbowyg-box,
.trumbowyg-editor {
  width: calc(100% - 15px);
  height: calc(100% - 30px);
}

#ticket-new-update .mdl-step__content {
  overflow: hidden;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.comment-box.card .nav-pills > li > a,
.conversation .nav-pills > li > a {
  font-size: 20px;
  text-align: center;
  color: #2196f3;
}
.comment-box.card .nav-pills > li > a .fa,
.conversation .nav-pills > li > a .fa {
  width: 24px;
}

.comment-box.card textarea {
  outline: 0 !important;
}
.comment-box.card textarea {
  width: 100%;
  min-height: 100px;
  padding: 20px;
  border: none;
  resize: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.comment-box.card .card-body {
  padding: 0;
}
/* .comment-box.card .card-footer {
} */
.comment-box.card .btn-primary {
  min-width: 100px;
}

.conversation {
  padding: 30px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.conversation .media {
  margin-top: 10px;
  padding-top: 30px;
  border-top: 1px solid #e8e8e8;
  position: relative;
}
.conversation .media:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}
.conversation .media .remove {
  position: absolute;
  right: 0;
  bottom: 11px;
  width: 24px;
  text-align: center;
  color: #f44336;
  font-size: 18px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.conversation .media .remove .fa {
  width: 16px;
}
.conversation .media:hover .remove {
  opacity: 1;
  visibility: visible;
}
.conversation .media-object {
  width: 80px;
  height: 80px;
}
.conversation .media-left,
.conversation .media > .pull-left {
  padding-right: 30px;
}
.conversation .time,
.conversation .media-heading {
  color: #337ab7;
}
.conversation .media-heading small {
  margin-left: 10px;
}

.ticket-button {
  width: calc(50% - 0.125rem);
}
</style>
