<template>
  <div class="row help-content-row">
    <b-container fluid class="px-0">
      <b-row>
        <b-col>
          <b-alert show variant="info">
            <div class="alert-message d-flex justify-content-between">
              <h6 class="alert-heading float-left mt-2 mr-2">
                Results: {{ resultCount }}
              </h6>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center px-5">
          <b-list-group>
            <b-list-group-item
              class="flex-column align-items-start text-left cursor-pointer"
              v-for="(ticket, index) in searchTickets"
              :key="index"
              @click="readTicket(ticket.key)"
            >
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">#{{ ticket.id }}: {{ ticket.subject }}</h5>
                <small
                  >{{ formatDate(getLastUpdate(ticket.updates).date) }}
                </small>
              </div>

              <p
                class="mb-1"
                v-html="
                  getLastUpdate(ticket.updates).message.substr(0, 100) + '...'
                "
              ></p>

              <small
                v-html="
                  getLastUpdate(ticket.updates).type === 'new-message'
                    ? userAuthorName
                    : getLastUpdate(ticket.updates).author
                "
              ></small>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-center w-100 mt-3">
        <b-col class="d-flex justify-content-left">
          <b-link @click="goBack()">
            <i class="fas fa-arrow-circle-left"></i> Back
          </b-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import moment from "moment";

export default {
  name: "SearchTickets",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {},
  props: {
    search: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      ref: "tickets",
      bind: [],
      tickets: [],
    };
  },
  watch: {
    userUid() {
      this.bindObject(this.ref + "/" + this.userUid, null, "tickets");
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "userAuthorName"]),
    userUid() {
      return this.userProfile.uid;
    },
    searchText() {
      const search = this.search;
      return search && search.length > 0 ? search.toLowerCase() : null;
    },
    searchTickets() {
      if (this.searchText) {
        const tickets = [];
        this.tickets.forEach((ticket) => {
          let match = false;
          if (ticket.subject.includes(this.searchText)) {
            match = true;
          }
          if (ticket.updates) {
            Object.values(ticket.updates).forEach((update) => {
              if (update.subject && update.subject.includes(this.searchText)) {
                match = true;
              }
              if (update.message && update.message.includes(this.searchText)) {
                match = true;
              }
            });
          }
          if (match) {
            const t = { ...ticket };
            t.key = ticket[".key"];
            tickets.push(t);
          }
        });
        return tickets;
      } else return [];
    },
    resultCount() {
      return this.searchTickets.length;
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).fromNow();
    },
    readTicket(id) {
      this.navigate("read-ticket", { id: id });
    },
    getLastUpdate(updates) {
      const updatesArray = Object.values(updates);
      return updatesArray[updatesArray.length - 1];
    },
    goBack() {
      this.$emit("end-search");
    },
  },
  created() {
    this.bindObject(this.ref + "/" + this.userUid, null, "tickets");
  },
};
</script>

<style scoped>
.need-support {
  width: 100%;
  max-width: 300px;
  max-height: 300px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
  text-align: center;
  /* background: #f5f5f5; */
  border-radius: 0.25rem;
}
</style>
