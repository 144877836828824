<template>
  <div id="help-container" class="container-fluid">
    <div class="row">
      <div class="col-xs-12 help-banner text-center">
        <div class="help-banner-text">
          <h1>How can we help?</h1>
          <b-input-group>
            <b-form-input
              v-model="search.text"
              @keyup.enter="search.show = true"
              placeholder="Search for keywords, topics..."
            ></b-form-input>

            <template v-slot:append>
              <b-dropdown :text="search.type" variant="primary" right>
                <b-dropdown-item @click="setSearchType('Articles')"
                  >Articles</b-dropdown-item
                >
                <b-dropdown-item @click="setSearchType('Tickets')"
                  >Tickets</b-dropdown-item
                >
              </b-dropdown>
              <b-button variant="primary" @click="search.show = true"
                ><i class="fas fa-search"></i
              ></b-button>
            </template>
          </b-input-group>
        </div>
      </div>
    </div>
    <knowledge-base
      v-if="page === 'kb' && search.show === false"
      v-on:show-tickets="page = 'tickets'"
    ></knowledge-base>
    <search-knowledge-base
      v-if="search.type === 'Articles' && search.show === true"
      v-on:end-search="search.show = false"
      :search="search.text"
    ></search-knowledge-base>
    <tickets
      v-if="page === 'tickets' && search.show === false"
      v-on:show-kb="page = 'kb'"
    ></tickets>
    <search-tickets
      v-if="search.type === 'Tickets' && search.show === true"
      v-on:end-search="search.show = false"
      :search="search.text"
    ></search-tickets>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import KnowledgeBase from "@/components/KnowledgeBase.vue";
import SearchKnowledgeBase from "@/components/SearchKnowledgeBase.vue";
import Tickets from "@/components/Tickets.vue";
import SearchTickets from "@/components/SearchTickets.vue";
//import moment from "moment";

export default {
  name: "Help",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    "knowledge-base": KnowledgeBase,
    tickets: Tickets,
    "search-knowledge-base": SearchKnowledgeBase,
    "search-tickets": SearchTickets,
  },
  data() {
    return {
      ref: "help",
      bind: [],
      page: "kb",
      search: {
        text: "",
        type: "Articles",
        show: false,
      },
    };
  },
  watch: {
    activePage: {
      immediate: true,
      handler(page) {
        console.log("Page: " + page);
        this.page = page;
      },
    },
    page: {
      immediate: false,
      handler(page) {
        if (page === "tickets") {
          history.replaceState({}, null, "/help/tickets");
          this.search.type = "Tickets";
        } else {
          history.replaceState({}, null, "/help/kb");
          this.search.type = "Articles";
        }
      },
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    activePage() {
      return this.$route.params.page === "tickets" ? "tickets" : "kb";
    },
  },
  methods: {
    setSearchType(type) {
      this.search.type = type;
    },
  },
};
</script>

<style scoped>
#help-container {
  width: calc(100% + 16px);
  height: calc(100% + 18px);
  max-width: 1068px;
}

.help-banner {
  background: url(../../assets/img/laptop_1280.jpg) no-repeat left;
  background-size: cover;
  width: 100%;
  height: 400px;
  margin-top: -10px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  padding-left: 25px;
  padding-right: 25px;
  box-shadow: inset 0 -3px 6px -6px black;
  border-radius: 0.25rem;
}

.help-banner-text {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  text-align: center;
}

.help-banner-text > h1 {
  color: white;
  margin-bottom: 40px;
}

.help-content-row {
  padding: 18px 0 0 0;
}

.top-buffer {
  margin-top: 15px;
}

.jumbotron-reduced {
  margin-top: 5px;
  padding-top: 18px;
  padding-bottom: 18px;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.jumbotron-reduced .img-responsive {
  height: 130px;
  padding-top: 12px;
}

.page-header-bg {
  width: calc(100% + 30px);
  height: 170px;
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 18px;
  padding-top: 18px;
  padding-bottom: 18px;
  background-color: #d9edf7;
  text-align: center;
}

.page-header-bg .img-responsive {
  height: 130px;
  padding-top: 12px;
}

.page-header-bg-narrow {
  width: calc(100% + 30px);
  height: 100px;
  margin-top: 0;
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #d9edf7;
  text-align: center;
}

.page-header-bg-narrow .img-responsive {
  height: 80px;
  padding-top: 4px;
}

.nav-row {
  padding-top: 18px;
}
</style>
