<template>
  <div class="row help-content-row">
    <b-container fluid class="px-0">
      <b-row>
        <b-col>
          <b-alert show variant="info">
            <div class="alert-message d-flex justify-content-between">
              <h6 class="alert-heading float-left mt-2 mr-2">
                <i class="fas fa-question-circle mr-2"></i> Have you tried
                checking our knowledge base?
              </h6>
              <b-button variant="secondary" @click="$emit('show-kb')"
                >View Knowledge Base</b-button
              >
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-container fluid>
            <b-row>
              <b-col sm="12" md="8">
                <b-container fluid>
                  <b-row v-if="ticketsNeedingAttention.length > 0">
                    <b-col class="text-center">
                      <h5 class="my-2 text-muted">Need Attention</h5>
                      <b-list-group>
                        <b-list-group-item
                          href="#"
                          class="flex-column align-items-start text-left"
                          v-for="(ticket, index) in ticketsNeedingAttention"
                          :key="index"
                          @click="readTicket(ticket['.key'])"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              #{{ ticket.id }}: {{ ticket.subject }}
                            </h5>
                            <small
                              >{{
                                formatDate(getLastUpdate(ticket.updates).date)
                              }}
                              <b-dropdown
                                id="dropdown-buttons"
                                variant="link"
                                toggle-class="text-decoration-none"
                                class="ml-2"
                                size="sm"
                                no-caret
                                no-flip
                                right
                              >
                                <template v-slot:button-content>
                                  <i class="fas fa-ellipsis-v text-dark"></i
                                  ><span class="sr-only">Menu</span>
                                </template>
                                <b-dropdown-item-button
                                  variant="danger"
                                  @click.stop="showCloseTicket(ticket['.key'])"
                                  ><i class="far fa-window-close"></i> Close
                                  Ticket</b-dropdown-item-button
                                >
                              </b-dropdown>
                            </small>
                          </div>

                          <p
                            class="mb-1"
                            v-html="
                              String(
                                getLastUpdate(ticket.updates).message
                              ).substr(0, 100) + '...'
                            "
                          ></p>

                          <small
                            v-html="
                              getLastUpdate(ticket.updates).type ===
                              'new-message'
                                ? userAuthorName
                                : getLastUpdate(ticket.updates).author
                            "
                          ></small>
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="ticketsOpen.length > 0">
                    <b-col class="text-center">
                      <h5 class="my-2 text-muted">Open</h5>
                      <b-list-group>
                        <b-list-group-item
                          href="#"
                          class="flex-column align-items-start text-left"
                          v-for="(ticket, index) in ticketsOpen"
                          :key="index"
                          @click="readTicket(ticket['.key'])"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              #{{ ticket.id }}: {{ ticket.subject }}
                            </h5>
                            <small
                              >{{
                                formatDate(getLastUpdate(ticket.updates).date)
                              }}
                              <b-dropdown
                                id="dropdown-buttons"
                                variant="link"
                                toggle-class="text-decoration-none"
                                class="ml-2"
                                size="sm"
                                no-caret
                                no-flip
                                right
                              >
                                <template v-slot:button-content>
                                  <i class="fas fa-ellipsis-v text-dark"></i
                                  ><span class="sr-only">Menu</span>
                                </template>
                                <b-dropdown-item-button
                                  variant="danger"
                                  @click.stop="showCloseTicket(ticket['.key'])"
                                  ><i class="far fa-window-close"></i> Close
                                  Ticket</b-dropdown-item-button
                                >
                              </b-dropdown>
                            </small>
                          </div>

                          <p
                            class="mb-1"
                            v-html="
                              String(
                                getLastUpdate(ticket.updates).message
                              ).substr(0, 100) + '...'
                            "
                          ></p>

                          <small
                            v-html="
                              getLastUpdate(ticket.updates).type ===
                              'new-message'
                                ? userAuthorName
                                : getLastUpdate(ticket.updates).author
                            "
                          ></small>
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="ticketsClosed.length > 0">
                    <b-col class="text-center">
                      <h5 class="my-2 text-muted">Closed</h5>
                      <b-list-group>
                        <b-list-group-item
                          href="#"
                          class="flex-column align-items-start text-left text-muted"
                          v-for="(ticket, index) in ticketsClosed"
                          :key="index"
                          @click="readTicket(ticket['.key'])"
                        >
                          <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                              #{{ ticket.id }}: {{ ticket.subject }}
                            </h5>
                            <small>{{ formatDate(ticket.close_date) }} </small>
                          </div>

                          <p
                            class="mb-1"
                            v-html="
                              String(
                                getLastUpdate(ticket.updates).message
                              ).substr(0, 100) + '...'
                            "
                          ></p>

                          <small
                            v-html="
                              getLastUpdate(ticket.updates).type ===
                              'new-message'
                                ? userAuthorName
                                : getLastUpdate(ticket.updates).author
                            "
                          ></small>
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="
                      ticketsNeedingAttention.length === 0 &&
                      ticketsOpen.length === 0 &&
                      ticketsClosed.length === 0
                    "
                  >
                    <b-col class="text-center">
                      <h5 class="my-2 text-muted">You have no tickets</h5>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
              <b-col sm="12" md="4">
                <div class="need-support">
                  <h5>Need support?</h5>
                  <p>Click below to open a new support ticket</p>
                  <b-button
                    variant="outline-primary"
                    :to="{ name: 'submit-ticket' }"
                    >Submit ticket</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal_close"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Close Ticket"
      ok-title="Close"
      @ok="closeTicket"
    >
      <p class="my-2">Please explain why you are closing the ticket:</p>
      <b-form-textarea
        v-model="close.message"
        placeholder="Explanation..."
        rows="6"
        max-rows="6"
        no-resize
      ></b-form-textarea>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Ticket from "@/firebase/tickets";
import { DateTime } from "luxon";

export default {
  name: "Tickets",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {},
  data() {
    return {
      ref: "tickets",
      bind: [],
      tickets: [],
      close: {
        key: "",
        message: "",
      },
    };
  },
  watch: {
    userUid() {
      this.bindObject(this.ref + "/" + this.userUid, null, "tickets");
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "userAuthorName"]),
    userUid() {
      return this.userProfile.uid;
    },
    ticketsNeedingAttention() {
      return this.tickets.filter((ticket) => {
        return ticket.status === "Needs Attention";
      });
    },
    ticketsOpen() {
      return this.tickets.filter((ticket) => {
        return ticket.status === "Open";
      });
    },
    ticketsClosed() {
      return this.tickets.filter((ticket) => {
        return ticket.status === "Closed";
      });
    },
  },
  methods: {
    formatDate(date) {
      const dt = DateTime.fromMillis(date);
      return dt.toRelative();
    },
    readTicket(id) {
      this.navigate("read-ticket", { id: id });
    },
    getLastUpdate(updates) {
      const updatesArray = Object.values(updates);
      return updatesArray[updatesArray.length - 1];
    },
    showCloseTicket(key) {
      console.log(key);
      this.close.key = key;
      this.$bvModal.show("modal_close");
    },
    closeTicket() {
      const t = new Ticket();
      t.close(this.close.key, this.close.message).catch((error) => {
        console.log(error);
        window.toastr.error(
          "An error occurred while closing the ticket. Please try again later."
        );
      });
      this.close.message = "";
      this.close.key = "";
    },
  },
  created() {
    this.bindObject(this.ref + "/" + this.userUid, null, "tickets");
  },
};
</script>

<style lang="scss" scoped>
.need-support {
  width: 100%;
  max-width: 300px;
  max-height: 300px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
  text-align: center;
  border-radius: 0.25rem;
}

body[data-theme="light"] {
  .need-support {
    background: #fff;
  }
}

body[data-theme="dark"] {
  .need-support {
    background: #293042;
  }
}
</style>
