<template>
  <b-card
    no-body
    class="overflow-hidden category-card shadow-sm"
    @click="$emit('click')"
  >
    <b-row no-gutters>
      <b-col md="5">
        <b-card-img
          :src="category.image"
          :alt="category.name"
          class="rounded-0"
        ></b-card-img>
      </b-col>
      <b-col md="7">
        <b-card-body :title="category.name">
          <b-card-text>
            {{ category.description }}
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";

export default {
  name: "CategoryCard",
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  mixins: [FirebaseMixin, UtilsMixin],
  components: {},
  data() {
    return {
      image: "",
    };
  },
  // watch: {
  //   category: {
  //     immediate: true,
  //     handler(category) {
  //       console.log(category);
  //       if ("image" in category) {
  //         this.getImageUrl("articles/" + category.image).then((url) => {
  //           this.image = url;
  //         });
  //       }
  //     },
  //   },
  // },
};
</script>

<style scoped>
.category-card {
  width: 500px;
  height: 137px;
  margin: 0.5rem;
  cursor: pointer;
}
</style>
