<template>
  <div class="row help-content-row">
    <b-container fluid class="px-0">
      <b-row>
        <b-col>
          <b-alert
            show
            variant="info"
            class="d-flex justify-content-left w-100"
          >
            <div class="alert-message">
              <h6 class="alert-heading float-left mt-2 mr-2">
                Results: {{ resultCount }}
              </h6>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-container fluid class="category-container">
            <b-row class="d-flex justify-content-center w-100 nav-row">
              <b-col
                sm="12"
                md="8"
                class="d-flex justify-content-center w-100"
              ></b-col>
            </b-row>
            <b-row class="d-flex justify-content-center w-100">
              <b-col
                sm="12"
                md="8"
                v-for="(article, index) in searchArticles"
                :key="index"
                class="d-flex justify-content-center w-100"
              >
                <b-card
                  :title="article.name"
                  :sub-title="article.description"
                  class="mb-3 w-100 cursor-pointer"
                  @click="readArticle(article.root)"
                >
                  <b-card-text>
                    {{ article.text.substring(0, 100) + "..." }}
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center w-100">
              <b-col sm="12" md="8" class="d-flex justify-content-left">
                <b-link @click="goBack()">
                  <i class="fas fa-arrow-circle-left"></i> Back
                </b-link>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";

export default {
  name: "SearchKnowledgeBase",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {},
  props: {
    search: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      ref: "articles",
      bind: ["articles", "articleIndex"],
      images: [],
      root: ["articles"],
      path: [],
      articleIndex: [],
    };
  },
  watch: {
    root: {
      immediate: true,
      handler(root) {
        this.bindObject(root.join("/"), null, "articles");
      },
    },
    path: {
      immediate: false,
      handler() {
        history.replaceState({}, null, "/help/kb/" + this.pathString);
      },
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    searchText() {
      const search = this.search;
      return search && search.length > 0 ? search.toLowerCase() : null;
    },
    searchArticles() {
      if (this.searchText) {
        const articles = [];
        this.articleIndex.forEach((aidx) => {
          console.log(aidx);
          if (aidx.keywords.includes(this.searchText)) {
            articles.push(this.getArticle(aidx.root));
          }
        });
        return articles;
      } else return [];
    },
    resultCount() {
      return this.searchArticles.length;
    },
  },
  methods: {
    readArticle(root) {
      this.getPathFromRoot(root).then((path) => {
        console.log("Path: " + path);
        this.$router.push("/help/articles/" + path.replace("/", ""));
      });
    },
    goBack() {
      this.$emit("end-search");
    },
    async setRootFromPath(routePath) {
      console.log("Route Path: " + routePath);
      const segments = routePath.split("/");
      let root = ["articles"];
      let path = [];
      for (const segment of segments) {
        const snapshot = await this.getObjectSnapshot(
          root.join("/"),
          null,
          true
        );
        snapshot.forEach(function (childSnapshot) {
          const key = childSnapshot.key;
          const category = childSnapshot.val();
          if (
            category.type === "category" &&
            category.slug === segment.toLowerCase()
          ) {
            root.push(key, "articles");
            path.push({
              name: category.name,
              slug: category.slug,
              root: [...root],
            });
          }
        });
      }
      this.root = [...root];
      this.path = [...path];
    },
    async getPathFromRoot(root) {
      console.log("Root: " + root);
      const segments = root.split("/");
      let currentRoot = "";
      let path = "";
      for (const [index, section] of segments.entries()) {
        if (index > 0) {
          currentRoot += "/";
        }
        currentRoot += section;
        console.log(currentRoot);
        const snapshot = await this.getObjectSnapshot(currentRoot, null, true);
        const article = snapshot.val();
        if (segments.length === index + 1) {
          path += "/" + snapshot.key;
        } else if ("slug" in article) {
          path += "/" + article.slug;
        }
      }
      return path;
    },
    getArticle(root) {
      const sections = root.split("/");
      let location = { ...this.articles };
      for (const [index, section] of sections.entries()) {
        if (index > 0) {
          if (section in location) {
            location = { ...location[section] };
          } else {
            location = {};
            break;
          }
        }
      }
      location.root = root;
      return location;
    },
  },
  created() {},
};
</script>

<style scoped>
.category-container {
  max-width: 1068px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
