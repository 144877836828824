import axios from "axios";
import firebase from "firebase";

export default class Ticket {
  remoteUrl = "https://us-central1-api-faker.cloudfunctions.net/helpdesk/";
  //remoteUrl = "http://localhost:5001/api-faker/us-central1/helpdesk/";

  // Fetch an id token from Firebase for API authentication
  async getToken() {
    const user = firebase.auth().currentUser;
    const response = await user.getIdToken(false);
    return response;
  }

  // Submit a ticket using cloud function
  async submit(ticket) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "tickets/submit",
      data: ticket,
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }

  // Update a ticket using cloud function
  async update(key, ticket) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "tickets/" + key + "/update",
      data: ticket,
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }

  // Close a ticket using cloud function
  async close(key, message = null) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "tickets/" + key + "/close",
      data: { message: message },
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment,
      },
    });
  }
}
