<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-md-8 mx-auto">
        <b-card
          :title="article.name"
          :sub-title="article.description"
          css="shadow my-3"
        >
          <b-card-text v-html="article.text" class="my-4"></b-card-text>

          <b-link @click="goBack()">
            <i class="fas fa-arrow-circle-left"></i> Back
          </b-link>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
//import moment from "moment";

export default {
  name: "ReadArticle",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {},
  data() {
    return {
      ref: "articles",
      bind: [],
      refkey: null,
      article: {
        name: "",
        text: "",
      },
      root: [],
      path: [],
    };
  },
  watch: {
    refkey: {
      immediate: false,
      handler(key) {
        this.bindObject(this.root.join("/"), key, "article");
      },
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    pathString() {
      return this.path
        .map((breadcrumb) => {
          return breadcrumb.slug;
        })
        .join("/");
    },
  },
  methods: {
    async setRootFromPath(routePath) {
      console.log("Route Path: " + routePath);
      const segments = routePath.split("/");
      let root = ["articles"];
      let path = [];
      for (const segment of segments) {
        const snapshot = await this.getObjectSnapshot(
          root.join("/"),
          null,
          true
        );
        snapshot.forEach(function (childSnapshot) {
          const key = childSnapshot.key;
          const category = childSnapshot.val();
          if (
            category.type === "category" &&
            category.slug === segment.toLowerCase()
          ) {
            root.push(key, "articles");
            path.push({
              name: category.name,
              slug: category.slug,
              root: [...root],
            });
          }
        });
      }
      this.root = [...root];
      this.path = [...path];
      this.refkey = segments[segments.length - 1];
    },
    goBack() {
      this.$router.push("/help/kb/" + this.pathString);
    },
  },
  created() {
    //this.refkey = this.$route.params.id;
    console.log(this.$route.params.path);
    this.setRootFromPath(this.$route.params.path);
  },
};
</script>

<style>
img {
  max-width: 100%;
  height: auto;
}
</style>

<style scoped>
.article-title {
  /*background: url(~/assets/laptop_1280.jpg) no-repeat left;*/
  background-size: cover;
}
</style>
