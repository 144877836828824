<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card shadow-sm text-left">
          <div class="card-body pb-0">
            <h5 class="card-title">Submit Ticket</h5>
          </div>
          <form id="frmAdd" action="#" method="post">
            <ul class="list-group list-group-flush">
              <li id="tab-content" class="list-group-item">
                <p>
                  Please describe the issue you are having as thoroughly as
                  possible.
                </p>
                <input
                  id="inpNewTicketSubject"
                  type="text"
                  class="form-control mb-3"
                  placeholder="Subject"
                  v-model="ticket.subject"
                />
                <text-editor
                  placeholder="Thorough description..."
                  rounded="all"
                  preset="basic"
                  v-model="ticket.message"
                ></text-editor>
              </li>
              <li class="list-group-item">
                <b-button
                  :to="{ name: 'help', params: { page: 'tickets' } }"
                  variant="secondary"
                  class="mr-2"
                  ><i class="far fa-arrow-alt-circle-left"></i> Back</b-button
                >
                <b-button variant="primary" @click="submit">
                  <b-spinner small v-if="processing"></b-spinner>
                  <span v-if="!processing"
                    ><i class="fas fa-paper-plane"></i> Submit</span
                  ></b-button
                >
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Ticket from "@/firebase/tickets";
import TextEditor from "@/components/TextEditor";
import { DateTime } from "luxon";

export default {
  name: "SubmitTicket",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: { TextEditor },
  data() {
    return {
      ref: "tickets",
      bind: [],
      ticket: {
        subject: "",
        message: "",
      },
      processing: false,
    };
  },
  watch: {},
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["userAuthorName"]),
  },
  methods: {
    formatDate(date) {
      const dt = DateTime.fromMillis(date);
      return dt.toLocaleString();
    },
    submit() {
      this.processing = true;
      const t = new Ticket();
      t.submit(this.ticket)
        .then((response) => {
          console.log(response);
          const key = response.data.key;
          this.notyf.success("Ticket submitted successfully.");
          this.navigate("read-ticket", { id: key });
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while submitting the ticket. Please try again later."
          );
          this.processing = false;
        });
    },
  },
  created() {},
};
</script>

<style scoped>
.list-group {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
</style>
